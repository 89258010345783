import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUsername } from "utils/helper";
import { post, get, deleteRequest, apiUrls, put } from "utils/request";

// initial state
export const initialState = {
  userList: {
    list: [],
    recordsTotal: 0,
  },
  portRom: {
    loading: false,
    records: [],
    recordsTotal: 0,
    error: "",
  },
  detailRom: {
    date: "",
    shift: "",
    updatedAt: "",
    rom: {
      rom: "",
    },
    activityList: [],
    production: {
      production: [],
    },
  },
  attendances: {
    loading: false,
    records: [],
    error: "",
  },
  listAttendances: {
    loading: false,
    records: [],
    error: "",
  },
};

// fetch functions
export const fetchAvailableUsers = createAsyncThunk(
  "actual-port-rom/users",
  async (id) => {
    const response = await get(`${apiUrls.romUser}/user-available`);
    return response.data;
  }
);

export const fetchActualPortRom = createAsyncThunk(
  "actual-port-rom/filter",
  async (filter) => {
    const response = await post(`${apiUrls.actualRom}/admin/filter`, {
      columns: [
        {
          data: "date",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: filter.filterDate,
          searchable: true,
        },
        {
          data: "shift",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: filter.filterShift,
          searchable: true,
        },
      ],
      draw: 1,
      length: -1,
      order: [],
    });
    return response.data;
  }
);

export const fetchActualPortRomInterval = createAsyncThunk(
  "actual-port-rom/filter-interval",
  async (filter) => {
    const response = await post(`${apiUrls.actualRom}/admin/filter`, {
      columns: [
        {
          data: "date",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: filter.filterDate,
          searchable: true,
        },
        {
          data: "shift",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: filter.filterShift,
          searchable: true,
        },
      ],
      draw: 1,
      length: -1,
      order: [],
    });
    return response.data;
  }
);

export const fetchDetailActualPort = createAsyncThunk(
  "actual-port-rom/detail",
  async (id) => {
    const response = await get(`${apiUrls.actualRom}/admin/${id}`);
    return response.data;
  }
);

export const addActualPortRom = createAsyncThunk(
  "actual-port-rom/add",
  async (body) => {
    let response;
    try {
      response = await post(
        `${apiUrls.actualRom}/admin/add?userName=${getUsername()}&date=${
          body.date
        }`,
        body
      );

      return response;
    } catch (e) {
      return e;
    }
  }
);

export const adminSaveActualPortRom = createAsyncThunk(
  "actual-port-rom/adminSave",
  async ({ body, date, userName, id }) => {
    let response;

    try {
      response = await put(
        `${apiUrls.actualRom}/admin/${id}/save?date=${date}&userName=${userName}`,
        body
      );

      return response;
    } catch (e) {
      return e;
    }
  }
);

export const saveActualRomProduction = createAsyncThunk(
  "actual-port-rom/save",
  async ({ data, username, id }) => {
    return await put(
      `${apiUrls.actualRom}/admin/${id}/production/save?userName=${username}`,
      data
    );
  }
);

const dataUrlToBase64 = (dataUrl) => {
  const commaIndex = dataUrl.indexOf(",");
  if (commaIndex === -1) {
    throw new Error("Invalid data URL");
  }
  const base64 = dataUrl.substring(commaIndex + 1);
  return base64;
};

const convertImageDataUrlToBase64 = (input) => {
  const dataUrlPattern = /^data:image\/\w+;base64,/;
  if (dataUrlPattern.test(input)) {
    return dataUrlToBase64(input);
  } else {
    return input;
  }
};

export const addActualRomActivity = createAsyncThunk(
  "actual-port-rom/add-activity",
  async ({ data, username }) => {
    const payload = {
      ...data,
      documentList: data.documentList.map((item) => {
        return item?.base64
          ? {
              ...item,
              base64: convertImageDataUrlToBase64(item?.base64),
            }
          : item;
      }),
    };
    return await post(
      `${apiUrls.actualRom}/admin/activity/add?userName=${username}`,
      payload
    );
  }
);

export const saveActualRomActivity = createAsyncThunk(
  "actual-port-rom/save-activity",
  async ({ data, username, id }) => {
    const payload = {
      ...data,
      documentList: data.documentList.map((item) => {
        return item?.base64
          ? {
              ...item,
              base64: convertImageDataUrlToBase64(item?.base64),
            }
          : item;
      }),
    };
    return await put(
      `${apiUrls.actualRom}/admin/activity/${id}/save?userName=${username}`,
      payload
    );
  }
);

export const deleteActualRom = createAsyncThunk(
  "actual-port-rom/delete",
  async ({ id, username }) => {
    return await deleteRequest(
      `${apiUrls.actualRom}/admin/${id}?userName=${username}`
    );
  }
);

export const fetchDetailOperatorRom = createAsyncThunk(
  "actual-port-rom/attendance/detail-operator",
  async () => {
    const response = await get(
      `${apiUrls.attendanceRom}/detail-operator/RomActual`
    );

    return response?.data;
  }
);

export const fetchListAttendance = createAsyncThunk(
  "actual-port-rom/attendance/list-attendance",
  async (option) => {
    const response = await post(
      `${apiUrls.attendanceRom}/list-attendance`,
      option
    );

    return response?.data;
  }
);

export const saveAttendance = createAsyncThunk(
  "actual-port-rom/attendance/list-attendance",
  async (params) => {
    let response;

    try {
      response = await post(
        `${apiUrls.attendanceRom}/attendance/save?userName=${params.userName}`,
        params.options
      );
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

export const handleDeleteRomActivity = createAsyncThunk(
  "actual-port-rom/delete-activity",
  async (param) => {
    const { userName, id } = param;
    let response;

    try {
      response = await deleteRequest(
        `${apiUrls.actualRom}/admin/activity/${id}?userName=${userName}`
      );
      return response.data;
    } catch (e) {
      return e;
    }
  }
);

// slicing
const actualAdminRom = createSlice({
  name: "ACTUAL_ADMIN_ROM",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableUsers.fulfilled, (state, action) => {
      state.userList = {
        list: action.payload.list || [],
        recordsTotal: action.payload.recordsTotal || 0,
      };
    });

    builder.addCase(fetchActualPortRom.rejected, (state, action) => {
      state.portRom.loading = false;
    });
    builder.addCase(fetchActualPortRom.fulfilled, (state, action) => {
      state.portRom.loading = false;
      state.portRom.records = action.payload?.list;
      state.portRom.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(fetchActualPortRom.pending, (state) => {
      state.portRom.loading = true;
    });
    builder.addCase(fetchActualPortRomInterval.fulfilled, (state, action) => {
      state.portRom.records = action.payload?.list;
      state.portRom.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(fetchDetailActualPort.fulfilled, (state, action) => {
      let activityList = JSON.parse(
        JSON.stringify(action.payload.activityList)
      );

      const activityListSortedByStartTime = activityList.sort(function (a, b) {
        let aStartTime = `${a.startTime}`;
        let bStartTime = `${b.startTime}`;
        if (action.payload.shift === "Day") {
          return aStartTime.localeCompare(bStartTime);
        } else if (action.payload.shift === "Night") {
          aStartTime = a.startTime.startsWith("0")
            ? "B" + aStartTime
            : "A" + aStartTime;
          bStartTime = b.startTime.startsWith("0")
            ? "B" + bStartTime
            : "A" + bStartTime;
          return aStartTime.localeCompare(bStartTime);
        }
      });

      for (var i = 0; i < activityListSortedByStartTime.length; i++) {
        if (i + 1 != activityListSortedByStartTime.length) {
          activityListSortedByStartTime[i].endTime =
            activityListSortedByStartTime[i + 1].startTime;
        } else {
          if (action.payload.shift.toLowerCase() === "night") {
            activityListSortedByStartTime[i].endTime = "07:00";
          } else {
            activityListSortedByStartTime[i].endTime = "19:00";
          }
        }

        const time = activityListSortedByStartTime[i].startTime.split(":");
        const timeFormat = `${time[0]}:${time[1]}`;
        activityListSortedByStartTime[i].timeFormat = timeFormat;
      }

      state.detailRom = {
        ...action.payload,
        activityList: activityListSortedByStartTime,
      };
    });

    builder.addCase(fetchDetailOperatorRom.pending, (state, action) => {
      state.attendances.loading = true;
    });
    builder.addCase(fetchDetailOperatorRom.fulfilled, (state, action) => {
      const payload = action.payload.filter((e) => e.entityStatus === 1);
      const payloadFilter = payload.sort((a, b) =>
        a["name"].toLowerCase() < b["name"].toLowerCase() ? -1 : 1
      );

      state.attendances.records = payloadFilter;
      state.attendances.loading = false;
    });
    builder.addCase(fetchDetailOperatorRom.rejected, (state, action) => {
      state.attendances.loading = false;
      state.attendances.error = "Invalid get data";
    });

    builder.addCase(fetchListAttendance.pending, (state, action) => {
      state.listAttendances.loading = true;
    });
    builder.addCase(fetchListAttendance.fulfilled, (state, action) => {
      state.listAttendances.records = action.payload;
      state.listAttendances.loading = false;
    });
    builder.addCase(fetchListAttendance.rejected, (state, action) => {
      state.listAttendances.loading = false;
      state.listAttendances.error = "Invalid get data";
    });
  },
});

export const portActualRom = (state) => state.actualAdminRom.portRom;
export const detailDataSelector = (state) => state.actualAdminRom.detailRom;
export const romAttendanceSelector = (state) =>
  state.actualAdminRom.attendances;
export const romListAttendanceSelector = (state) =>
  state.actualAdminRom.listAttendances;

export const romUserSelector = (state) => state.actualAdminRom.userList;

export default actualAdminRom.reducer;
