import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrls, get, post } from 'utils/request';

// initial state
export const initialState = {
  portRom: {
    loading: false,
    records: [],
    error: '',
  },
};

// fetch functions
export const getROMExcavatorDashboard = createAsyncThunk(
  "rom-excavator/dashboard",
  async (params) => {
    const { dateStart, dateEnd } = params

    const response = await get(`${apiUrls.romExcavatorDashboard}/timesheet?startTime=${dateStart}&endTime=${dateEnd}`);

    return response.data;
  }
);

export const exportActualToExcel = createAsyncThunk('rom/excavator/exportExcel', async (params) => {
  const object = {
    contractor: params.contractor.value,
    endDate: params.endDate,
    shift: params.shift?.value,
    startDate: params.startDate
  };

  const response = await post(`${apiUrls.romExcavatorActual}/export-excel-file`, object);
  return response?.data;
})

// slicing
const romExcavatorDashboard = createSlice({
  name: 'DASHBOARD_ROM_EXCAVATOR',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getROMExcavatorDashboard.fulfilled, (state, action) => {
      state.portRom.loading = false;
      state.portRom.records = action.payload;
    });
    builder.addCase(getROMExcavatorDashboard.pending, (state) => {
      state.portRom.loading = true;
    });

    builder.addCase(exportActualToExcel.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(exportActualToExcel.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(exportActualToExcel.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid export data';
    });
  },
});

export const romDashboardSelector = (state) => state.romExcavatorDashboard.portRom;

export default romExcavatorDashboard.reducer;
