import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCompany, getUsername } from 'utils/helper';
import { post, get, deleteRequest, apiUrls, put } from 'utils/request';

// initial state
export const initialState = {
  portRom: {
    loading: false,
    records: [],
    recordsTotal: 0,
    error: '',
  },
  detailRom: {
    date: '',
    shift: '',
    updatedAt: '',
    rom: {
      rom: '',
    },
    activityList: [],
    production: {
      production: [],
    },
  },
  attendances: {
    loading: false,
    records: [],
    error: "",
  },
  listAttendances: {
    loading: false,
    records: [],
    error: "",
  },

};

// fetch functions
export const fetchActualPortRomExcavator = createAsyncThunk(
  'rom-excavator/filter',
  async (params) => {
    const { contractor, filterDate, filterShift } = params

    const response = await post(
      `${apiUrls.romExcavatorActual}/filter?contractor=${contractor}&date=${filterDate}&shift=${filterShift}`);
    return response.data;
  }
);

export const fetchActualPortRomExcavatorInterval = createAsyncThunk(
  'rom-excavator/filter-interval',
  async (params) => {
    const { contractor, filterDate, filterShift } = params

    const response = await post(
      `${apiUrls.romExcavatorActual}/filter?contractor=${contractor}&date=${filterDate}&shift=${filterShift}`);
    return response.data;
  }
);

export const fetchTimesheetActualPort = createAsyncThunk(
  'rom-excavator/detail-timesheet',
  async (id) => {
    const response = await get(`${apiUrls.romExcavatorActual}/${id}/timesheet`);
    return response.data;
  }
);

export const addActualPortRom = createAsyncThunk(
  'rom-excavator/add',
  async (body) => {
    let response;
    try {
      response = await post(
        `${apiUrls.romExcavatorActual}?userName=${getUsername()}`,
        body
      );

      return response
    } catch (e) {
      return e
    }
  }
);

export const adminSaveActualPortRom = createAsyncThunk(
  'rom-excavator/adminSave',
  async ({ body, userName, id }) => {
    let response;

    try {
      response = await put(
        `${apiUrls.romExcavatorActual}/${id}?userName=${userName}`,
        body
      );

      return response
    } catch (e) {
      return e
    }
  }
);

export const saveActualRomProduction = createAsyncThunk(
  'rom-excavator/save-production',
  async ({ data, activityId }) => {
    return await post(`${apiUrls.romExcavatorActual}/equipment-rom/activity/${activityId}/production?userName=${getUsername()}`, data)
  }
);

const dataUrlToBase64 = (dataUrl) => {
  const commaIndex = dataUrl.indexOf(',');
  if (commaIndex === -1) {
    throw new Error('Invalid data URL');
  }
  const base64 = dataUrl.substring(commaIndex + 1);
  return base64;
};

const convertImageDataUrlToBase64 = (input) => {
  const dataUrlPattern = /^data:image\/\w+;base64,/;
  if (dataUrlPattern.test(input)) {
    return dataUrlToBase64(input);
  } else {
    return input;
  }
}

export const addActualRomActivity = createAsyncThunk(
  'rom-excavator/add-activity',
  async ({ dataList, dataId, equipmentId, userName }) => {
    // const payload = {
    //   ...data,
    // documentList: data.documentList.map((item) => {
    //   return item?.base64 ? {
    //     ...item,
    //     base64: convertImageDataUrlToBase64(item?.base64)
    //   } : item;
    // })
    // };
    return await post(`${apiUrls.romExcavatorActual}/${dataId}/equipment-rom/${equipmentId}/activity?userName=${userName}`, dataList)
  }
);

export const saveActualRomActivity = createAsyncThunk(
  'rom-excavator/save-activity',
  async ({ data, username, id }) => {
    const payload = {
      ...data,
      documentList: data.documentList.map((item) => {
        return item?.base64 ? {
          ...item,
          base64: convertImageDataUrlToBase64(item?.base64)
        } : item;
      })
    };
    return await put(`${apiUrls.actualRom}/admin/activity/${id}/save?userName=${username}`, payload)
  }
);

export const deleteActualRom = createAsyncThunk(
  'rom-excavator/delete',
  async ({ id, username }) => {
    return await deleteRequest(
      `${apiUrls.romExcavatorActual}/${id}?userName=${username}`,
    );
  }
);

export const fetchDetailOperatorRom = createAsyncThunk(
  'rom-excavator/attendance/detail-operator',
  async () => {
    const response = await get(`${apiUrls.attendanceRom}/detail-operator/RomActual`);

    return response?.data;
  }
);

export const fetchListAttendance = createAsyncThunk(
  'rom-excavator/attendance/list-attendance',
  async (option) => {
    const response = await post(`${apiUrls.attendanceRom}/list-attendance`, option);

    return response?.data;
  }
);

export const saveAttendance = createAsyncThunk(
  'rom-excavator/attendance/list-attendance',
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.attendanceRom}/attendance/save?userName=${params.userName}`, params.options);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const handleDeleteRomActivity = createAsyncThunk(
  'rom-excavator/delete-activity',
  async (param) => {
    const { userName, id } = param
    let response

    try {
      response = await deleteRequest(`${apiUrls.romExcavatorActual}/equipment-rom/activity/${id}?userName=${userName}`);
      return response.data;
    }
    catch (e) {
      return e
    }
  }
);

export const adminSaveRomProductionPayload = createAsyncThunk(
  'rom-excavator/save-production-payload',
  async ({ body, id }) => {
    let response;

    try {
      response = await put(
        `${apiUrls.romExcavatorActual}/${id}/average-production?userName=${getUsername()}`,
        body
      );

      return response
    } catch (e) {
      return e
    }
  }
);

export const adminDeleteRomProduction = createAsyncThunk(
  'rom-excavator/save-production-payload',
  async ({ id }) => {
    let response;

    try {
      response = await deleteRequest(
        `${apiUrls.romExcavatorActual}/equipment-rom/production/${id}?userName=${getUsername()}`);

      return response
    } catch (e) {
      return e
    }
  }
);

// slicing
const romExcavatorActual = createSlice({
  name: 'ACTUAL_ADMIN_ROM_EXCAVATOR',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActualPortRomExcavator.rejected, (state, action) => {
      state.portRom.loading = false;
    });
    builder.addCase(fetchActualPortRomExcavator.fulfilled, (state, action) => {
      state.portRom.loading = false;
      state.portRom.records = action.payload?.list;
      state.portRom.recordsTotal = action.payload?.recordsTotal;
    });
    builder.addCase(fetchActualPortRomExcavator.pending, (state) => {
      state.portRom.loading = true;
    });
    builder.addCase(fetchActualPortRomExcavatorInterval.fulfilled, (state, action) => {
      state.portRom.records = action.payload?.list;
      state.portRom.recordsTotal = action.payload?.recordsTotal;
    });

    builder.addCase(fetchTimesheetActualPort.fulfilled, (state, action) => {
      let equipmentList = JSON.parse(
        JSON.stringify(action.payload.equipmentRomActivityList)
      );

      const contractor = getCompany();
      const isAdmin = contractor === "Borneo Indobara" ? true : false;

      if (!isAdmin) {
        if (equipmentList.length > 0) {
          let arr = [];

          equipmentList.forEach(el => {
            if (el.contractor === contractor) arr.push(el);
          });

          equipmentList = arr;
        }
      }

      for (let index in equipmentList) {
        const activitySorted = equipmentList[index].activityList.sort(function (a, b) {
          let aStartTime = `${a.startTime}`;
          let bStartTime = `${b.startTime}`;
          if (action.payload.shift === 'Day') {
            return aStartTime.localeCompare(bStartTime);
          } else if (action.payload.shift === 'Night') {
            aStartTime = a.startTime.startsWith('0')
              ? 'B' + aStartTime
              : 'A' + aStartTime;
            bStartTime = b.startTime.startsWith('0')
              ? 'B' + bStartTime
              : 'A' + bStartTime;
            return aStartTime.localeCompare(bStartTime);
          }
        });

        for (var i = 0; i < activitySorted.length; i++) {
          if (i + 1 != activitySorted.length) {
            activitySorted[i].endTime =
              activitySorted[i + 1].startTime;
          } else {
            if (action.payload.shift.toLowerCase() === 'night') {
              activitySorted[i].endTime = '07:00';
            } else {
              activitySorted[i].endTime = '19:00';
            }
          }

          const time = activitySorted[i].startTime.split(":");
          const timeFormat = `${time[0]}:${time[1]}`;
          activitySorted[i].timeFormat = timeFormat;
        }

        equipmentList[index].activityList = activitySorted;
      }

      state.detailRom = {
        ...action.payload,
        equipmentRomActivityList: equipmentList,
      };
    });

    builder.addCase(fetchDetailOperatorRom.pending, (state, action) => {
      state.attendances.loading = true;
    });
    builder.addCase(fetchDetailOperatorRom.fulfilled, (state, action) => {
      const payload = action.payload.filter(e => e.entityStatus === 1)
      const payloadFilter = payload.sort((a, b) => a['name'].toLowerCase() < b['name'].toLowerCase() ? -1 : 1)

      state.attendances.records = payloadFilter;
      state.attendances.loading = false;
    });
    builder.addCase(fetchDetailOperatorRom.rejected, (state, action) => {
      state.attendances.loading = false;
      state.attendances.error = 'Invalid get data';
    });

    builder.addCase(fetchListAttendance.pending, (state, action) => {
      state.listAttendances.loading = true;
    });
    builder.addCase(fetchListAttendance.fulfilled, (state, action) => {
      state.listAttendances.records = action.payload;
      state.listAttendances.loading = false;
    });
    builder.addCase(fetchListAttendance.rejected, (state, action) => {
      state.listAttendances.loading = false;
      state.listAttendances.error = 'Invalid get data';
    });
  },
});

export const portActualRom = (state) =>
  state.romExcavatorActual.portRom;
export const detailDataSelector = (state) =>
  state.romExcavatorActual.detailRom;
export const romAttendanceSelector = (state) => state.romExcavatorActual.attendances;
export const romListAttendanceSelector = (state) => state.romExcavatorActual.listAttendances;

export default romExcavatorActual.reducer;
