import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiUrls, get, post, getV2ActualRom } from "utils/request";

const initialState = {
  romList: [],
  performanceStatistic: [],
  delayStatistic: {
    crushingDelayStatisticList: [],
    delayStatisticList: [],
  },
  isLoading: false,
  error: null,
  errorMessages: "",
};

export const getROMList = createAsyncThunk(
  "RomDashboardPerformance/getROMList",
  async () => {
    const response = await get(`${apiUrls.romTls}/performance`);
    return response?.data; // Ensure the response data is returned
  }
);
export const getPerformanceStatistic = createAsyncThunk(
  "RomDashboardPerformance/getPerformanceStatistic",
  async (params) => {
    const { shift, rom, startDate, endDate, filter } = params;

    const theshift = shift === "All" ? "" : shift;

    const response = await post(`${apiUrls.romTls}/performance`, {
      shift: theshift,
      rom: rom,
      startDate: startDate,
      endDate: endDate,
      filter: filter,
    });

    return response?.data;
  }
);

export const getDelayStatistic = createAsyncThunk(
  "RomDashboardPerformance/getDelayStatistic",
  async (params) => {
    const { endDate, startDate, shift } = params;

    const response = await get(`${apiUrls.configRom}/rom`, {
      endDate: endDate,
      startDate: startDate,
      shift: shift,
    });
    return response?.data;
  }
);

const RomDashboardPerformanceSlice = createSlice({
  name: "RomDashboardPerformance",
  initialState,
  reducers: {
    resetState: (state) => {
      state.performanceStatistic = [];
      state.delayRank = [];
      state.totalTime = [];
      state.delayStatistic = {
        crushingDelayStatisticList: [],
        delayStatisticList: [],
      };
      state.isLoading = false;
      state.error = null;
      state.errorMessages = "";
    },
  },
  extraReducers: {
    [getROMList.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = "";
    },
    [getROMList.fulfilled]: (state, action) => {
      state.romList = action.payload.data;

      state.isLoading = false;
      state.error = null;
      state.errorMessages = "";
    },
    [getROMList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
    [getPerformanceStatistic.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = "";
    },
    [getPerformanceStatistic.fulfilled]: (state, action) => {
      let payload = action?.payload.data.dashboard || [];
      if (payload && payload.length > 0) {
        payload = payload.sort((a, b) => {
          const romA = a.rom
            .split(/(\d+)/)
            .map((part) => (isNaN(part) ? part : parseInt(part, 10)));
          const romB = b.rom
            .split(/(\d+)/)
            .map((part) => (isNaN(part) ? part : parseInt(part, 10)));

          for (let i = 0; i < Math.max(romA.length, romB.length); i++) {
            if (romA[i] === undefined) return -1;
            if (romB[i] === undefined) return 1;
            if (romA[i] < romB[i]) return -1;
            if (romA[i] > romB[i]) return 1;
          }
          return 0;
        });
      }
      state.totalTime = action?.payload.data.totalTime;
      state.delayRank = action?.payload.data.delayRank;

      state.performanceStatistic = payload;

      state.isLoading = false;
      state.error = null;
      state.errorMessages = "";
    },
    [getPerformanceStatistic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },

    [getDelayStatistic.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = "";
    },
    [getDelayStatistic.fulfilled]: (state, action) => {
      state.delayStatistic = action.payload;

      state.isLoading = false;
      state.error = null;
      state.errorMessages = "";
    },
    [getDelayStatistic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
  },
});

export const { actions } = RomDashboardPerformanceSlice;
export const { resetState } = actions; // Remove resetDate and setDate
export const romDashboardPerformanceSelector = (state) =>
  state.romDashboardPerformance;
export default RomDashboardPerformanceSlice.reducer;
