import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCompany } from 'utils/helper';
import { apiUrls, get, post } from 'utils/request';

// initial state
export const initialState = {
  listRomConfiguration: [],
  listRomConfigurationDefault: [],
  romConfLogs: {
    statusLogList: [],
    statusLogListTotal: 0,
  }
};

export const getRomConfiguration = createAsyncThunk(
  "rom-excavator/configuration",
  async (params) => {
    const { contractor, search } = params

    const response = await get(`${apiUrls.romExcavatorConfiguration}?contractorName=${contractor}&search=${search}`);

    return response.data;
  }
);

export const saveRomConfiguration = createAsyncThunk(
  "rom-excavator/configuration-save",
  async (params) => {
    const response = await post(`${apiUrls.romExcavatorConfiguration}`, params);

    return response.data;
  }
);

export const getRomConfigurationLog = createAsyncThunk(
  "rom-excavator/configuration/log",
  async (params) => {
    const contractor = getCompany();

    const response = await post(`${apiUrls.romExcavatorConfiguration}/logs`,
      {
        columns: [
          {
            data: "configuration.equipmentRom.contractor",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: contractor === "Borneo Indobara" ? "" : contractor,
            searchable: true,
          },
          {
            data: "configuration.location.name",
            orderable: true,
            search: {
              regex: false,
              value: "",
            },
            searchValue: "",
            searchable: true,
          },
        ],
        search: {},
        draw: params?.pageIndex ? params.pageIndex : 1,
        length: params?.recordLength ? params.recordLength : 10,
        start: 0,
      });

    return response.data;
  }
);

// slicing
const romExcavatorConfiguration = createSlice({
  name: 'ACTUAL_ROM_CONFIGURATION',
  initialState,
  reducers: {
    onChangeLocation: (state, action) => {
      const payload = action.payload;

      state.listRomConfiguration[payload.index]["location"] = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRomConfiguration.fulfilled, (state, action) => {
      let payload = action.payload;
      const company = getCompany();
      let arr = [];
      let romIdArr = [];

      if (payload.length > 0) {
        payload = payload.sort((a, b) => (a.contractor > b.contractor) ? 1 : -1);

        if (company !== "Borneo Indobara") {
          payload.forEach(el => {
            if (el.contractor === company) arr.push(el);
          });
        }
        else arr = payload
      }

      arr.forEach(el => {
        if (el.id) romIdArr.push(el.id);
      });

      state.listRomConfiguration = arr;
      state.listRomConfigurationDefault = arr;
    });

    builder.addCase(getRomConfigurationLog.fulfilled, (state, action) => {
      const payload = action.payload;

      state.romConfLogs = { statusLogList: payload.list, statusLogListTotal: payload.recordsTotal };
    });
  },
});

export const romExcavatorConfigurationDefaultSelector = (state) => state.romExcavatorConfiguration.listRomConfigurationDefault;
export const romExcavatorConfigurationSelector = (state) => state.romExcavatorConfiguration.listRomConfiguration;
export const romExcavatorConfigurationLogsSelector = (state) => state.romExcavatorConfiguration.romConfLogs;

export const {
  onChangeLocation,
} = romExcavatorConfiguration.actions

export default romExcavatorConfiguration.reducer;
