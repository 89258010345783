
import axios from 'axios';
import { apiUrls } from "utils/request";
import moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { monthListEN } from 'utils/helper';

const formatDate = (date) => moment(date).format('YYYY-MM-DD');
const generatePayload = (params) => {
  const { contractor, pit, year } = params;
  const currentDate = moment().format('YYYY-MM-DD');
  let payload = {
    year: year,
  };

  if (contractor && contractor !== "All") { payload.contractor = contractor; } else { delete payload.contractor; }


  if (pit && pit !== "All") { payload.pit = pit; } else { delete payload.pit; }

  return payload;
};

const generatePayloadToDate = (params) => {
  const { contractor, pit, startDate, endDate } = params;
  const currentDate = moment().format('YYYY-MM-DD');

  let payload = {
    startDate: formatDate(startDate) || currentDate,
    endDate: formatDate(endDate) || currentDate,
  };

  if (contractor) {
    payload.contractor = contractor;
  }

  if (pit) {
    payload.pit = pit;
  }

  return payload;
};

export const initialState = {
  coalGettingFullYear: {
    loading: false,
    records: []
  },
  wasteRemovalFullYear: {
    loading: false,
    records: []
  },
  stpFullYear: {
    loading: false,
    records: []
  }
};

export const getCoalFullYearActualProd = createAsyncThunk(
  'dashboardPitFullYear/getCoalFullYearActualProd',
  async (params) => {
    const payload = generatePayload(params);
    const response = await axios.post(`${apiUrls.coalGetting}/dashboard/summary`, payload);
    return response.data;
  }
);

export const getWasteFullYearActualProd = createAsyncThunk(
  'dashboardPitFullYear/getWasteFullYearActualProd',
  async (params) => {
    const payload = generatePayload(params);
    const response = await axios.post(`${apiUrls.wasteRemoval}/dashboard/summary`, payload);
    return response.data;
  }
);


export const getSTPFullYearSummary = createAsyncThunk(
  'dashboardPitFullYear/getSTPFullYearSummary',
  async (params) => {
    const payload = generatePayload(params);
    const response = await axios.post(`${apiUrls.shortTermPlannings}/dashboard/summary`, payload);
    return response.data;
  }
);


const downloadFile = (data, filename = 'file.xlsx') => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const getExportWasteFullYear = (params) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrls.wasteRemoval}/export-excel/dashboard`, params, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/vnd.ms-excel',
      },
    });

    downloadFile(response.data, 'Waste Production.xlsx');

    dispatch({ type: 'GET_EXPORT_WASTE_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'GET_EXPORT_WASTE_ERROR', payload: error });
  }
};

export const getExportCoalFullYear = (params) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiUrls.coalGetting}/export-excel/dashboard`, params, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/vnd.ms-excel',
      },
    });

    downloadFile(response.data, 'Coal Production.xlsx');

    dispatch({ type: 'GET_EXPORT_COAL_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'GET_EXPORT_COAL_ERROR', payload: error });
  }
}
export const getCoalToDate = async (params) => {
  const payload = generatePayloadToDate(params);
  try {
    const response = await axios.post(`${apiUrls.coalGetting}/total-bcm`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getWasteToDate = async (params) => {
  const payload = generatePayloadToDate(params);

  try {
    const response = await axios.post(`${apiUrls.wasteRemoval}/total-bcm`, payload)
    return response.data;
  } catch (error) {
    return error;
  }
};;

const dashboardPitFullYearSlice = createSlice({
  name: 'dashboardPitFullYear',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoalFullYearActualProd.pending, (state, action) => {
      state.coalGettingFullYear.loading = true;
    });
    builder.addCase(getCoalFullYearActualProd.fulfilled, (state, action) => {
      state.coalGettingFullYear.records = action.payload || [];
      state.coalGettingFullYear.loading = false;
    });
    builder.addCase(getCoalFullYearActualProd.rejected, (state, action) => {
      state.coalGettingFullYear.loading = false;
    });
    builder.addCase(getWasteFullYearActualProd.pending, (state, action) => {
      state.wasteRemovalFullYear.loading = true;
    });
    builder.addCase(getWasteFullYearActualProd.fulfilled, (state, action) => {
      state.wasteRemovalFullYear.records = action.payload || [];
      state.wasteRemovalFullYear.loading = false;
    });
    builder.addCase(getWasteFullYearActualProd.rejected, (state, action) => {
      state.wasteRemovalFullYear.loading = false;
    });

    builder.addCase(getSTPFullYearSummary.pending, (state, action) => {
      state.stpFullYear.loading = true;
    });
    builder.addCase(getSTPFullYearSummary.fulfilled, (state, action) => {
      //state.stpFullYear.records = action.payload || [];
      const transformedData = {};

      // Iterate through each key in payload (e.g., "agreed", "rkab", "budget")
      Object.keys(action.payload).forEach((key) => {
        const data = [];

        action.payload[key].forEach(item => {
          monthListEN.forEach(month => {
            const monthKey = month.toLowerCase();
            const monthValue = Number(item[monthKey]) || 0; // Ensure monthValue is a number, default to 0 if undefined

            // Find or create the month+type entry in data
            let typeEntry = data.find(data => data.month === monthKey && data.type === item.type);

            if (!typeEntry) {
              // If the type for this month does not exist, create a new entry
              typeEntry = {
                month: monthKey,
                type: item.type, // waste_removal, coal_getting, etc.
                detailList: [],
                totalMine: 0 // Total bcm for this type within the month
              };
              data.push(typeEntry);
            }

            // Check if the contractor exists in the detailList within this type
            let contractorEntry = typeEntry.detailList.find(contractor => contractor.contractor === item.contractor);

            if (!contractorEntry) {
              // If contractor does not exist, create a new contractor entry
              contractorEntry = {
                contractor: item.contractor,
                pitList: []
              };
              typeEntry.detailList.push(contractorEntry);
            }

            // Append the pit information to the contractor's pitList
            contractorEntry.pitList.push({
              pit: item.pit,
              mine: monthValue
            });

            // Accumulate the bcm value into totalMine for the type
            typeEntry.totalMine += monthValue;
          });
        });

        // Store the transformed data for this key (e.g., "agreed", "rkab", "budget")
        transformedData[key] = data;
      });
      state.stpFullYear.records = transformedData
      state.stpFullYear.loading = false;
    });
    builder.addCase(getSTPFullYearSummary.rejected, (state, action) => {
      state.stpFullYear.loading = false;
    });
  }
})

export const coalFullYearSelector = (state) => state.dashboardPitFullYear.coalGettingFullYear;
export const wasteFullYearSelector = (state) => state.dashboardPitFullYear.wasteRemovalFullYear;
export const stpFullYearSelector = (state) => state.dashboardPitFullYear.stpFullYear;

export default dashboardPitFullYearSlice.reducer;
