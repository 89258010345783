import { Row } from "react-bootstrap";

export const TabPage = ({
  tabArray,
  activeTab = 0,
  setActive = () => { },
}) => {
  const changePage = (e, val) => {
    if (val.nav && (!setActive || setActive === null || val.isMovePage)) {
      if (val.page) {
        localStorage.setItem(
          'page',
          val.page
        );
      }

      e.preventDefault();
      return window.location.href = val.nav
    }
    return setActive(val.nav)
  }

  return (
    <Row>
      <div className="tab-action">
        <div className="left">
          {tabArray.map((val, index) => {
            return (
              <div key={val.key || index} className={activeTab === index ? "active" : ""} onClick={(e) => changePage(e, val)}>
                <h3 className='p-15'>{val.title}</h3>
              </div>
            )
          })}
        </div>
      </div>
    </Row>
  );
};

export default TabPage;
